import React from "react";
import PostDate from "../components/PostDate";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { Link } from "gatsby";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import CodeBlock from '../components/CodeBlock';
import SEO from '../components/seo';
import Img from "gatsby-image"

export default function Post({data}) {
    const post = data.mdx;
    const shortcodes = { 
        Link,
        pre: props => <CodeBlock {...props} />,
    };
    const date = new Date(post.frontmatter.date.replace('Z', ''));

    let featuredImg = post.frontmatter?.featuredImage?.childImageSharp.fluid;
    let featuredImageCaption = post.frontmatter?.featuredImageCaption;

    function getCaption() {
        return {__html: featuredImageCaption};
    }

    return (
        <Layout>
            <SEO title={post.frontmatter.title} />

            <div className="post-layout">
                <div>
                    <PostDate month={date.getMonth() + 1} day={date.getDate()} year={date.getFullYear()} />
                </div>

                <div className="post-content readable-width">
                    <Link to="/" className="mb-2">Home</Link>
                    
                    <div className="article-container">
                        <h1>{post.frontmatter.title}</h1>

                        {featuredImg && (
                            <figure className="featured-image">
                                <Img fluid={featuredImg} />
                                {featuredImageCaption && (
                                    <figcaption className="featured-image-caption mt-2" dangerouslySetInnerHTML={getCaption()}></figcaption>
                                )}
                            </figure>
                        )}

                        <MDXProvider components={shortcodes}>
                            <MDXRenderer>{post.body}</MDXRenderer>
                        </MDXProvider>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export const query = graphql`
  query($slug: String!) {
    mdx(fields: {slug: {eq: $slug}}) {
        body
        frontmatter {
            date
            title
            featuredImage {
                childImageSharp {
                    fluid(maxWidth: 860, maxHeight: 450) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            featuredImageCaption
        }
    }
  }
`