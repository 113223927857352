import PropTypes from "prop-types"
import React from "react"

const PostDate = ({ month, day, year }) => (
    <div className="date" title={`${month}/${day}/${year}`}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 349.63 346.64">
            <polygon className="a" points="218.09 40.56 183.09 40.86 182.57 7.87 217.57 7.56 218.09 40.56"/>
            <polygon className="b" points="218.09 40.56 183.09 40.86 182.57 7.87 217.57 7.56 218.09 40.56"/>
            <polygon className="c" points="218.09 40.56 183.09 40.86 182.57 7.87 217.57 7.56 218.09 40.56"/>
            <polygon className="a" points="139.1 42.25 104.1 42.56 103.58 9.56 138.58 9.26 139.1 42.25"/>
            <polygon className="b" points="139.1 42.25 104.1 42.56 103.58 9.56 138.58 9.26 139.1 42.25"/>
            <polygon className="c" points="139.1 42.25 104.1 42.56 103.58 9.56 138.58 9.26 139.1 42.25"/>
            <path d="M294,282.4,11.14,285.78l2.79-227,9.31-11.89,260.55-2.82,14.5,9.76S295.39,206.22,294,282.4Z" style={{fill:`#cecece`}}/>
            <path d="M294,282.4,11.14,285.78l2.79-227,9.31-11.89,260.55-2.82,14.5,9.76S295.39,206.22,294,282.4Z" style={{strokeWidth:'22px', stroke: '#fff', fill:'none'}}/>
            <path d="M294,282.4,11.14,285.78l2.79-227,9.31-11.89,260.55-2.82,14.5,9.76S295.39,206.22,294,282.4Z" style={{strokeWidth:'8px', stroke: '#000', fill:'none'}}/>
            
            {month >= 10 ? (
                <text transform="translate(52 113) rotate(0)" textAnchor="end" style={{fontSize: '96px', fontFamily: 'Ultra', fill: '#fff', stroke: '#000', strokeWidth:'6px'}}>
                    1
                </text>
            ) : null}

            <text transform="translate(58 110) rotate(0)" textAnchor="start" style={{fontSize: '96px', fontFamily: 'Ultra', fill: '#fff', stroke: '#000', strokeWidth:'6px'}}>
                {month % 10}
            </text>

            {day >= 10 ? (
                <text transform="translate(168 294) rotate(-4.5)" textAnchor="end" style={{fontSize: '190px', fontFamily: 'Ultra', fill: '#fff', stroke: '#000', strokeWidth:'8px'}}>
                    {Math.floor(day / 10)}
                </text>
            ) : null}

            {day >= 10 ? (
                <text transform="translate(248 256) rotate(.5)" textAnchor="middle" style={{fontSize: '240px', fontFamily: 'Ultra', fill: '#fff', stroke: '#000', strokeWidth:'8px'}}>
                    {day % 10}
                </text>
            ) : (
                <text transform="translate(218 252) rotate(-2)" textAnchor="middle" style={{fontSize: '200px', fontFamily: 'Ultra', fill: '#fff', stroke: '#000', strokeWidth:'8px'}}>
                    {day % 10}
                </text>
            )}

            <text transform="translate(177.95 333.06) rotate(-1.09)" style={{fontSize: '48px', fontFamily: 'Lato', fontWeight: 700, fontStyle: 'italic'}}>
                {year}
            </text>

            <polygon className="h" points="154.75 104.44 91.38 152.39 86.13 142.98 149.49 95.03 154.75 104.44"/>
            <polygon points="154.75 104.44 91.38 152.39 86.13 142.98 149.49 95.03 154.75 104.44" style={{strokeWidth:'8px', stroke: '#fff', fill:'none'}}/>
            <polygon className="c" points="154.75 104.44 91.38 152.39 86.13 142.98 149.49 95.03 154.75 104.44"/>
        </svg>
    </div>
)

PostDate.propTypes = {
  month: PropTypes.number,
  day: PropTypes.number,
  year: PropTypes.number,
}

PostDate.defaultProps = {
  month: 1,
  day: 1,
  year: 2019,
}

export default PostDate